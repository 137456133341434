// AddData.js
import React, { useState } from 'react';
import axios from 'axios'; // Ensure you have axios installed
import config from '../../json/config.json';

const AddData = () => {
  const [category, setCategory] = useState('');
  const [topicName, setTopicName] = useState('');
  const [definition, setDefinition] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newData = {
      category,
      topics: [{ name: topicName, definition }],
    };

    try {
      const response = await axios.post(config.backEnd.hostURL  + '/studyvocabulary', newData); // Adjust the URL as needed
      if (response.status === 200) {
        setSuccessMessage('Data added successfully!');
        // Clear the form fields
        setCategory('');
        setTopicName('');
        setDefinition('');
      }
    } catch (error) {
      setErrorMessage('Failed to add data. Please try again.');
    }
  };

  return (
    <div className="add-data-container">
      <h2>Add New Topic</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Category:</label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Topic Name:</label>
          <input
            type="text"
            value={topicName}
            onChange={(e) => setTopicName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Definition:</label>
          <textarea
            value={definition}
            onChange={(e) => setDefinition(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Add Topic</button>
      </form>
      {successMessage && <p className="success">{successMessage}</p>}
      {errorMessage && <p className="error">{errorMessage}</p>}
    </div>
  );
};

export default AddData;
