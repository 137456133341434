import axios from 'axios';
import { useState, useEffect } from 'react';
import config from "../json/config.json";
import { Table } from 'react-bootstrap';

function Tutors() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(config.backEnd.hostURL + '/tutorform');
        if (response.data && response.data.length > 0) {
          // Sort data by timestamp in descending order (latest to earliest)
          const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData(); // Fetch data initially when the component mounts
  }, []); // Empty dependency array ensures useEffect runs only once
  
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Qualification</th>
          <th>Courses</th>
          <th>Others</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            <td>{item.fullName}</td>
            <td>{item.email}</td>
            <td>{item.mobile}</td>
            <td>{item.qualification}</td>
            <td>
              {item.topics && item.topics.length > 0 ? (
                item.topics.map((topic, index) => (
                  <div key={index}>{topic}</div>
                ))
              ) : (
                'No topics available'
              )}
            </td>
            <td>{item.otherCourses}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
export default Tutors;