import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Definitions.css'; // Import the CSS file for styling
import config from '../../json/config.json';

const CourseDropdown = () => {
  const [topics, setTopics] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [isSlideshowOpen, setIsSlideshowOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // State for the current index in the slideshow

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get(config.backEnd.hostURL + '/studyvocabulary'); // Update with your server URL
        const topicsData = {};

        // Process each document in the response
        response.data.forEach(item => {
          // Initialize the category if it doesn't exist
          if (!topicsData[item.category]) {
            topicsData[item.category] = {};
          }

          // Map each topic's name and definition within the category
          item.topics.forEach(topic => {
            topicsData[item.category][topic.name] = topic.definition;
          });
        });

        setTopics(topicsData); // Set the processed data to state
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };

    fetchTopics();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const allTopics = Object.entries(topics).flatMap(([category, topicList]) =>
      Object.entries(topicList).map(([topic, definition]) => ({ topic, definition }))
    );

    const selectedTopic = allTopics.find(item => item.topic.toLowerCase() === query);

    if (selectedTopic) {
      setFilteredTopics([selectedTopic]);
      setRecommendations(getRandomRecommendations(selectedTopic.topic));
    } else {
      setFilteredTopics([]);
      setRecommendations([]);
    }
  };

  const getRandomRecommendations = (currentTopic) => {
    const allTopics = Object.entries(topics).flatMap(([category, topicList]) =>
      Object.keys(topicList)
    );

    const filteredRecommendations = allTopics.filter(topic => topic !== currentTopic);
    const shuffled = filteredRecommendations.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setSearchQuery('');
    setRecommendations([]);
    setFilteredTopics([]);

    if (event.target.value) {
      const topicsList = Object.entries(topics[event.target.value] || {}).map(([topic, definition]) => ({
        topic,
        definition
      }));
      setFilteredTopics(topicsList);
    }
  };

  const openSlideshow = () => {
    setCurrentIndex(0);
    setIsSlideshowOpen(true);
  };

  const closeSlideshow = () => {
    setIsSlideshowOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredTopics.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + filteredTopics.length) % filteredTopics.length);
  };

  // Automatically advance the slide every 5 seconds
  useEffect(() => {
    if (isSlideshowOpen) {
      const timer = setInterval(() => {
        handleNext();
      }, 5000); // 5 seconds

      // Clear timer when component unmounts or slideshow is closed
      return () => clearInterval(timer);
    }
  }, [isSlideshowOpen, currentIndex]);

  return (
    <div className="course-dropdown-container">
      <input
        type="text"
        placeholder="Search for a topic..."
        className="search-input"
        value={searchQuery}
        onChange={handleSearch}
      />
      <select id="course-dropdown" onChange={handleCourseChange} value={selectedCourse} className="course-select">
        <option value="">--Select a Course--</option>
        {Object.keys(topics).map((category, index) => (
          <option key={index} value={category}>{category}</option>
        ))}
      </select>

      {filteredTopics.length > 0 && (
        <div className="topics-container">
          {filteredTopics.map((item, index) => (
            <div key={index} className="topic-card">
              <h4 className="topic-title">{item.topic}</h4>
              <p className="topic-definition">{item.definition}</p>
              <div className="recommendations-container">
                {recommendations.map((rec, index) => (
                  <button key={index} className="recommendation-button" onClick={() => handleSearch({ target: { value: rec } })}>
                    {rec}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {selectedCourse && (
        <button className="slideshow-button" onClick={openSlideshow}>
          Start Slideshow
        </button>
      )}

      {isSlideshowOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="close-button" onClick={closeSlideshow}>&times;</span>
            {filteredTopics.length > 0 && (
              <div className="slideshow-container">
                <h2>{filteredTopics[currentIndex].topic}</h2>
                <p>{filteredTopics[currentIndex].definition}</p>
                <div className="navigation-buttons">
                  <button onClick={handlePrev}>Previous</button>
                  <button onClick={handleNext}>Next</button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDropdown;
